<template>
    <div>
        <div class="backg">
            <div class="title">
                <img src="../../assets/image/login/title.png" alt="">
            </div>
        </div>
        <div class="loginDiv">
            <div class="userDiv">
                <img src="~assets/image/login/user.png" alt="" class="userImg">
                <input type="text" placeholder="请输入用户名" v-model="clientId">
                <img src="~assets/image/login/delete.png" alt="" class="delete" @click="deleteUser">
            </div>
            <div class="passWordDiv">
                <img src="~assets/image/login/password.png" alt="" class="password">
                <input type="password" placeholder="请输入密码" v-model="secret" ref="secretPwd">
                <img src="~assets/image/login/hidden.png" alt="" class="hidden" @click="hiddenPwd">
            </div>
            <p class="forgetPwd" @click="forgetPwd">忘记密码?</p>
            <button class="loginBtn" @click="goToLogin">立即登录</button>
        </div>
    </div>
</template>
<script>
 import {toLogin} from '../../network/login'
                 

export default{
    name:'Login',
    data(){
        return {
            clientId:'',
            secret:'',
        }
    },
    mounted(){
        document.querySelector('body').setAttribute('style','background-color:#EEEEEE')
    },
    beforeDestroy(){
        document.querySelector('body').removeAttribute('style')
    },
    methods:{
        goToLogin(){
            if(this.clientId.length == 0){
                this.$toast.fail('请填写用户名');
                return
            }
             if(this.secret.length == 0){
                this.$toast.fail('请输入密码');
                return
            }
            this.$toast.loading({
                message: '努力加载中...',
                forbidClick: false,
                duration:0
            });
            toLogin(this.clientId,this.secret).then(res=>{
                this.$toast.clear();
                if(res.success){
                     localStorage.setItem('token',res.data);
                     localStorage.setItem('username',res.extra.user.name);
                     this.$router.replace('/')
                  
                }else{
                    this.$toast.fail(res.msg);
                }
            })
            
        },
        deleteUser(){
            if(this.clientId){
               this.clientId = '';
            }
             
        },
        hiddenPwd(){
            let type = this.$refs.secretPwd.getAttribute('type')
            
            if(type == 'password'){
                this.$refs.secretPwd.setAttribute('type','text'); 
            }else{
                this.$refs.secretPwd.setAttribute('type','password');
            }
        },
        forgetPwd(){
             this.$toast.fail('请联系管理员处理');
        }
    }
}
</script>
<style scoped>
.backg{
    width: 100%;
    height: 536px;
    background: linear-gradient(-55deg, #2998F8, #08A3FE);
    overflow: hidden;
}
.title{
    margin: 127px auto;
    text-align: center;
    margin-bottom: 0;
}
.title img{
    width: 177px;
}
.loginDiv{
    margin-top: -136px;
    width: 94%;
    margin-left: 3%;
    height: 570px;
    background: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
}
.userDiv,.passWordDiv{
    width: 600px;
    background: #F0F0F0;
    border-radius: 45px;
    margin: 60px auto;
    display: flex;
    align-items: center;
    position: relative;
}
.userDiv input,.passWordDiv input{
    border: none;
    outline: none;
    line-height: 90px;
    background-color: #F0F0F0;
    margin-left: 30px;
}
.userImg{
    width: 28px;
    margin-left: 40px;
}
.delete{
    width: 50px;
    position: absolute;
    right: 20px;
    top: 20px;

}
.password{
    width: 31px;
    margin-left: 38px;
}
.hidden{
    width: 42px;
      position: absolute;
    right: 26px;
    top: 24px;
}
.forgetPwd{
    width: 100%;
    text-align: right;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ADADAD;
    padding-right: 61px;
}
.loginBtn{
    width: 600px;
    height: 90px;
    background: linear-gradient(-90deg, #2998F8, #08A3FE);
    border-radius: 45px;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 30px;
    margin: 61px auto;
    display: block;
    border: none;
}
</style>
