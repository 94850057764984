import axios from './axios'


//登陆 获取token
export function toLogin(clientId,secret) {
  return axios({
    url: 'token',
    method:'post',
    data:{
        clientId,
        secret,
        clientType: 'user'
    }
  })
}
//判断权限 获取角色
//true-主管 false-大堂经理
export function validRole(){
    return axios({
        url:'api/assessmenttask/validRole'
    })
}


